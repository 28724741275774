<template>
    <div>
        <el-upload
                class="upload-demo"
                ref="upload"
                action="#"
                :on-change="handleChange"
                :file-list="fileList"
                :data="uploadData"
                multiple
                accept=".mp4"
                :show-file-list="false"
                :limit="10"
                :before-upload="beforeUpload"
                drag
                :auto-upload="false">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，后点击开始上传</div>

        </el-upload>

        <div class="upload-demo uploading" style="width: 660px" v-if="showUploadInfo">
            <div class="upload-info upload-item">
                <span class="upload-info-name upload-info-txt">{{fileName}}</span>
                <span class="upload-info-size upload-info-txt">{{fileSize}}</span>
                <span class="upload-info-progress upload-info-txt">{{fileProgress}}%</span>
            </div>
            <div class="upload-progress upload-item"><el-progress :percentage="fileProgress" :show-text="false"></el-progress></div>
            <div class="upload-number upload-item upload-info-txt">正在上传 {{uploadIndex}}/{{fileCount}}</div>
        </div>


    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "videoUpload",
        data(){
            return {
                fileList:[],
                tableData:[],
                uploadUrl:'',
                uploadData:{},
                timeout: '',
                partSize: '',
                parallel: '',
                retryCount: '',
                retryDuration: '',
                region: 'cn-shanghai',
                userId: '1692684512114334',
                file: null,
                authProgress: 0,
                uploadDisabled: true,
                resumeDisabled: true,
                pauseDisabled: true,
                uploader: null,
                statusText: '',
                showUploadInfo:false,
                fileCount:0,
                fileName:'',
                fileSize:0,
                fileProgress:0,
                uploadIndex:0,
            }
        },
        methods:{
            ...mapActions('common',['getVideoUploadAccess']),

            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handleChange(file){
                this.fileList.push(file)
                var userData = '{"Vod":{}}'
                var uploader = this.createUploader()

                uploader.addFile(file.raw, null, null, null, userData)
                uploader.startUpload()
                this.showUploadInfo = true

            },
            beforeUpload(){
                console.log('beforeUploadbeforeUploadbeforeUpload')
            },
            createUploader (type) {
                let self = this
                let uploader = new AliyunUpload.Vod({
                    timeout: self.timeout || 60000,
                    partSize: self.partSize || 1048576,
                    parallel: self.parallel || 1,
                    retryCount: self.retryCount || 1,
                    retryDuration: self.retryDuration || 2,
                    region: self.region,
                    userId: self.userId,
                    // 添加文件成功
                    addFileSuccess: function (uploadInfo) {
                        self.fileCount += 1
                        self.uploadDisabled = false
                        self.resumeDisabled = false
                        self.statusText = '添加文件成功, 等待上传...'
                        console.log("addFileSuccess: " + uploadInfo.file.name)
                    },
                    // 开始上传
                    onUploadstarted: function (uploadInfo) {
                        // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
                        // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
                        // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
                        // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
                        // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
                        // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)

                        self.fileName = uploadInfo.file.name
                        self.fileSize = self.formatFileSize(uploadInfo.file.size)
                        let data = {
                            name:uploadInfo.file.name,
                            type:2,
                            isTranscode:1
                        }
						console.log(uploadInfo)
                        self.getVideoUploadAccess(data).then(res => {

                            let videoId = res.data.VideoId;
                            let uploadAddress = res.data.UploadAddress
                            let uploadAuth = res.data.UploadAuth

                            uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress,videoId)
                            self.statusText = '文件开始上传...'
                            console.log("onUploadStarted:" + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)

                        })

                    },
                    // 文件上传成功
                    onUploadSucceed: function (uploadInfo) {
                        console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
                        self.statusText = '文件上传成功!'
                        // self.fileProgress = 0
                        self.fileName = uploadInfo.file.name
                        self.fileSize = self.formatFileSize(uploadInfo.file.size)
                        self.uploadIndex += 1

                        self.tableData.push({
                            name: uploadInfo.file.name,
                            type: uploadInfo.file.name.split('.').splice(-1),
                            size:  uploadInfo.file.size,
                            status: 'success'
                        })
                        self.$message.success('视频上传成功')

                    },
                    // 文件上传失败
                    onUploadFailed: function (uploadInfo, code, message) {
                        console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
                        self.statusText = '文件上传失败!'
                        self.tableData.push({
                            name: uploadInfo.file.name,
                            type: uploadInfo.file.name.split('.').splice(-1),
                            size:  uploadInfo.file.size,
                            status: 'fail'
                        })
                        self.$message.error('视频上传视频')
                    },

                    // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
                    onUploadProgress: function (uploadInfo, totalSize, progress) {
                        console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
                        let progressPercent = Math.ceil(progress * 100)
                        self.fileProgress = progressPercent


                    },

                    // 全部文件上传结束
                    onUploadEnd: function (uploadInfo) {
                        console.log("onUploadEnd: uploaded all the files")
                        self.statusText = '文件上传完毕'
                        self.showUploadInfo = false

                        self.$emit('onChange',self.tableData)

                    }
                })
                return uploader
            },
            formatFileSize(size)
            {
                return  parseFloat((size/1024)/1024).toFixed(2) + 'M';
            }

        }
    }
</script>

<style>
.uploading{
    position: absolute;
    top: 0;
    left:0 ;
    width: 660px;
    height: 80px;
    border: 1px solid #ebebeb;
    background-color: #fafafa;
    border-radius: 5px;
    padding:20px;
}

    .upload-info-txt{
        display: inline-block;
        font-size: 12px;
        color: #0e0e0e;
    }
    .upload-info-size{
        padding:0 20px
    }
    .upload-progress{
        padding:10px 0
    }
    .upload-info-progress{
        color: #409EFF;
        float: right;
    }

</style>
